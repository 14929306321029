// app/javascript/controllers/edit_goal_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log('connect');
}

static targets = ["popup"]

  showPopup() {
    // Show the popup box when the icon is clicked
    this.popupTarget.style.display = "block"
  }

  closePopup() {
    // Close the popup box when the user is done
    this.popupTarget.style.display = "none"
  }
}

