import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "title", "content", "date", "author"];

  connect() {
    this.itemTargets.forEach((item) => {
      const notificationId = item.dataset.id;
      const readStatus = localStorage.getItem(`notification_${notificationId}`);
      item.style.opacity = readStatus === "read" ? "0.5" : "1";
      
    });

    if (this.itemTargets.length > 0) {
      this.showNotificationDetails({ currentTarget: this.itemTargets[0] });
    }
  }

  showNotificationDetails(event) {
    const element = event.currentTarget;
    const title = element.dataset.title;
    const content = element.dataset.content;
    const date = element.dataset.date;
    const author = element.dataset.author;
    const notificationId = element.dataset.id;

    this.titleTarget.innerText = title;
    this.contentTarget.innerHTML = content;
    this.dateTarget.innerText = date;
    this.authorTarget.innerText = author;    

    if (element.style.opacity !== "0.5") {
      element.style.opacity = "0.5";
      localStorage.setItem(`notification_${notificationId}`, "read");
    }
  }
}