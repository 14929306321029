import { Controller } from '@hotwired/stimulus';
import { Japanese } from 'flatpickr/dist/l10n/ja';

export default class extends Controller {
  connect() {
    console.log('Article Controller connected!, Html to text change');
    this.multipleSelect();
    this.date_picker();
  }

  multipleSelect() {
    const selectElement = $('.js-example-basic-multiple', this.element);

    if (selectElement) {
      $(selectElement).select2();
    }
  }

  date_picker() {
    const dateInput = this.element.querySelectorAll('.date-input');
    flatpickr(dateInput, {
      enableTime: true,
      minDate: 'today',
      locale: Japanese,
      wrap: true,
      disableMobile: 'true',
      allowInvalidPreload: true,
      altInput: true,
      altFormat: 'Y年m月d日  H時:i分',
    });
  }

  showArticles(event) {
    const categoryId = event.target.dataset.categoriesCategoryIdValue;

    this.updateArticleFrame(categoryId);
  }

  updateArticleFrame(categoryId) {
    const articleListFrame = document.getElementById('article-list-frame');
    const articleOverviewFrame = document.getElementById('article-overview-frame');
    this.loadSpinner(articleListFrame);
    this.loadSpinner(articleOverviewFrame, true);

    fetch(`/student/articles/show_articles/${categoryId}`, {
      headers: { Accept: 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => {
        document.getElementById('article-list-frame').innerHTML = data.article_list_html;
        document.getElementById('article-overview-frame').innerHTML = data.article_overview_html;
      })
      .catch((error) => console.error('Error parsing JSON:', error));
  }

  updateArticleOverview(event) {
    const articleId = event.target.closest('.article-item').dataset.articleId;
    const turboFrame = document.getElementById('article-overview-frame');

    this.loadSpinner(turboFrame, true);

    fetch(`/student/articles/${articleId}/show_overview`, {
      headers: { Accept: 'text/html' },
    })
      .then((response) => response.text())
      .then((html) => {
        turboFrame.innerHTML = html;
      })
      .catch((error) => console.error('Error parsing JSON:', error));
  }

  toggleFavorite(e) {
    const articleId = e.target.dataset.articleId;
    const isFav = e.target.dataset.favorite === 'true';

    fetch(`/student/articles/${articleId}/toggle_favorite`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
      },
      body: JSON.stringify({ favorite: !isFav }),
    })
      .then((request) => request.json())
      .then((data) => {
        if (data.success) {
          if (e.target.classList.contains('bi-heart-fill')) {
            e.target.classList.remove('bi-heart-fill');
            e.target.classList.add('bi-heart');
          } else {
            e.target.classList.remove('bi-heart');
            e.target.classList.add('bi-heart-fill');
          }
        }
      })
      .catch((error) => console.error('Error parsing JSON:', error));
  }

  loadSpinner(element, overview = false) {
    element.innerHTML = `<div class="spinner-box d-flex w-100 h-100 justify-content-center align-items-center">
      <div class="pulse-container ${overview ? 'white' : ''}">  
        <div class="pulse-bubble pulse-bubble-1"></div>
        <div class="pulse-bubble pulse-bubble-2"></div>
        <div class="pulse-bubble pulse-bubble-3"></div>
      </div>
    </div>`;
  }
}
