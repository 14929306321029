import { Controller } from '@hotwired/stimulus';
import { Japanese } from 'flatpickr/dist/l10n/ja';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

// Connects to data-controller="event-date-fliter"
export default class extends Controller {
    connect() {
        console.log('Stimulus controller connected');
        this.date_picker();
    }

    date_picker() {
        
         // Initialize flatpickr for event_date_eq (Year/Month picker)
        var event_date_eq = flatpickr('.event_date_eq', {
            enableTime: false,
            locale: Japanese,
            wrap: true,
            disableMobile: true,
            allowInvalidPreload: true,
            altInput: true,
            plugins: [
                new monthSelectPlugin({
                    shorthand: true,
                    dateFormat: 'Y/m',
                    altFormat: 'Y年m月',
                    theme: 'material_blue',
                }),
            ],
            onChange: function (selectedDates, dateStr, instance) {
                // Automatically submit the form when a new month/year is selected
                const form = document.getElementById('filter_by_month') || 
                             document.getElementById('filter_by_month_report');
              form.submit();  
            },
        });
    }
}
